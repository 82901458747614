import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import '../index.css';
import '../index_mobile.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Reveal } from "./utils/Reveal";


const Contact = () => {

    const notify = () =>
        toast.success('Email sent.', {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
        });
    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_pclqsd5', 'template_qk3i6fa', form.current, 'QpUXGO3AYyJXYcKmc')
            .then((result) => {
                console.log(result.text);
                form.current.reset();
            }, (error) => {
                console.log(error.text);
            });
    };
    return (
        <div className="contact" id="contact">
            <Reveal>
                <p className="contact-header">Contact</p>
            </Reveal>
            <form ref={form} onSubmit={sendEmail} className="contact-form">
                <Reveal>
                    <input type="text" name="user_name" placeholder="Enter your name" className="small-field" />
                </Reveal>
                <Reveal>
                    <input type="email" name="user_email" placeholder="Enter your email" className="small-field" />
                </Reveal>
                <Reveal>
                    <textarea name="message" placeholder="Your message" className="large-field" />
                </Reveal>
                <Reveal>
                    <input type="submit" value="Send" className="button" onClick={notify} />
                </Reveal>
                <ToastContainer
                    position="top-center"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="dark"
                />
            </form>
        </div>
    )
}

export default Contact;