import React, { useState } from 'react';
import Navbar from './components/Navbar';
import Home from './components/Home';
import About from './components/About';
import Skills from './components/Skills';
import Project from './components/Project';
import Contact from './components/Contact';
import { Helmet } from "react-helmet";

function App() {
    const [activeSection, setActiveSection] = useState('home');
    return (
        <div className="master-container">
            <Helmet>
                <title>Dmitar Popovic Portfolio</title>
                <meta name="description" content="Explore Dmitar's portfolio showcasing expertise in full-stack development, projects, skills, and a decade-long background in aerospace engineering." />
                <meta name="keywords" content="Full-Stack Developer, Dmitar Portfolio, Dmitar Popović, React Developer, Django Developer, Aerospace Engineering, Software Projects, Skills, Web Development, Backend, Frontend, Python, React" />
                <meta name="author" content="Dmitar Popovic" />
            </Helmet>
            <Navbar currentSection={activeSection} onSectionChange={setActiveSection} />
            <Home currentSection={activeSection} onSectionChange={setActiveSection} />
            <About currentSection={activeSection} onSectionChange={setActiveSection} />
            <Skills currentSection={activeSection} onSectionChange={setActiveSection} />
            <Project currentSection={activeSection} onSectionChange={setActiveSection} />
            <Contact currentSection={activeSection} onSectionChange={setActiveSection} />
        </div>
    );
}

export default App;