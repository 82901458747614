import React from 'react';
import '../index.css';
import '../index_mobile.css';
import PropTypes from "prop-types";
import { Reveal } from "./utils/Reveal";
import Drive4NenaPhoto from "./images/drive4nena.jpg";

const Projects = () => {
    const YoutubeEmbed = ({ embedId }) => (
        <div className="video-responsive">
            <iframe
                width="100%"
                height="100%"
                src={`https://www.youtube.com/embed/${embedId}`}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                title="Embedded youtube"
            />
        </div>
    );



    YoutubeEmbed.propTypes = {
        embedId: PropTypes.string.isRequired
    };
    return (
        <div className="projects" id="projects">
            <Reveal>
                <h1>Drive4Nena React Website</h1>
            </Reveal>
            <div className="project-container">

                <div className="image-container">
                    <Reveal>
                        <a href="https://www.drive4nena.com" target="_blank" rel="noopener noreferrer">
                            <img src={Drive4NenaPhoto} alt="Drive4Nena" className="project-image" />
                        </a>
                    </Reveal>
                </div>

                <div className="video-desc-container">
                    <div className="project-description">
                        <Reveal><p>
                            Developed a modern and user-friendly React-based website for Drive4Nena, a trucking and transportation company established in 2006.
                            <br></br>
                            The website highlights their dedication to delivering efficient logistics solutions and supporting drivers with competitive pay and stable freight opportunities.
                        </p></Reveal>

                        <Reveal><h2>Key Features</h2></Reveal>
                        <ul>
                            <li><Reveal>
                                Dynamic Content: Integrated a responsive homepage with a professional layout that communicates the company’s mission and values effectively.
                            </Reveal></li>
                            <li><Reveal>
                                Interactive Elements: Used scroll effects to create engaging visual transitions.
                            </Reveal></li>
                            <li><Reveal>
                                Scalability: Designed the architecture to allow future updates and feature integrations seamlessly.
                            </Reveal></li>
                            <li><Reveal>
                                Interactive Contact Form: Developed a user-friendly contact form that enables potential drivers and clients to easily get in touch with Drive4Nena. The form validates user input in real-time and integrates with email services to securely send inquiries.
                            </Reveal></li>

                        </ul>
                    </div>
                    <div className="project-skills">
                        <Reveal><div className="project-skill">React</div></Reveal>
                        <Reveal><div className="project-skill">HTML</div></Reveal>
                        <Reveal><div className="project-skill">CSS</div></Reveal>
                    </div>



                </div>

            </div>
            <div className="separator-container">

            </div>
            <Reveal><h1>
                Management Software
            </h1></Reveal>
            <div className="project-container">



                <YoutubeEmbed embedId="92fo5FkgKnw?si=G0TezZg1BP19ARGB" />



                <div className="video-desc-container">


                    <div className="project-description">


                        <Reveal><p>Company Care is a comprehensive tool for efficient HR management.
                            <br></br>Enhance HR management with 'Company Care', a software designed for streamlining HR tasks and improving employee experiences.
                        </p></Reveal>

                        <Reveal><h2>
                            Key Features
                        </h2></Reveal>
                        <ul>
                            <li><Reveal>Time Management for tracking vacations and leaves </Reveal></li>
                            <li><Reveal>Onboarding and Offboarding for new hires and exiting employees </Reveal></li>
                            <li><Reveal>Annual Performance Reviews, and </Reveal></li>
                            <li><Reveal>Travel Requests and Expense Management</Reveal></li>

                        </ul>
                        <p>It integrates team absences into manager's calendars for better planning. The software also manages the company’s organizational structure, aligning the workforce with strategic goals, monitoring expiring employee contracts, and managing travel compensation policies.</p>

                    </div>


                    <div className="project-skills">
                        <Reveal><div className="project-skill">Python</div></Reveal>
                        <Reveal><div className="project-skill">Django</div></Reveal>
                        <Reveal><div className="project-skill">MySQL</div></Reveal>
                        <Reveal><div className="project-skill">Rest API</div></Reveal>
                        <Reveal><div className="project-skill">GIT</div></Reveal>
                        <Reveal><div className="project-skill">Hostinger</div></Reveal>
                        <Reveal><div className="project-skill">JavaScript</div></Reveal>
                        <Reveal><div className="project-skill">React</div></Reveal>
                        <Reveal><div className="project-skill">HTML</div></Reveal>
                        <Reveal><div className="project-skill">CSS</div></Reveal>
                    </div>

                </div>

            </div>


        </div >

    )
}

export default Projects;